html, body {
    background-color: #0F0F0F;
    overflow: overlay;
    scrollbar-color: #FEFEFE rgba(0, 0, 0, 0);
    scrollbar-arrow-color: rgba(0, 0, 0, 0);
}


body::-webkit-scrollbar {
    width: 12px;
}
body::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    background-color: #FEFEFE;
    border-radius: 8px;
}

